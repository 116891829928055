<template>
  <div>
    <div class="container">
      <div class="bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item class="highlight">直播</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-divider></el-divider>
      <el-container class="main_content">
        <div class="select-con">
          <Select :isLive="true" @getData="getData"></Select>
        </div>
        <el-main>
          <div class="statistics">
            <span class="filtrate">筛选结果</span>
            <span class="num" v-text="cardList.length"></span>
            <span class="filtrate">条</span>
          </div>
          <div class="layout">
            <Card
              v-if="cardList.length > 0"
              @getData="getData"
              @fakeRefresh="fakeRefresh"
              :teachers="cardList"
              class="Grid"
            ></Card>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
          <div class="Pager">
            <!-- 分页 -->
            <list-page
              v-if="cardList.length > 0"
              @pageChanged="getCardList"
              :curPage="page"
              :total="cardList.length"
              :pSize="20"
            ></list-page>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import Card from "../../components/RecCard/index";
import ListPage from "../../components/ListPage/index";
import Select from "../../components/Select/index.vue";
import apiLive from "@/api/live";
// import publicfun from "@/scripts/publicfun";
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
// import fakedata from "../../models/appointment";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    Card,
    Select,
    ListPage,
  },
  data() {
    return {
      cardList: [],
      page: 1,
      total: 0,
      pageSize: 20,
      turnnum: 0,
      queryForm: {},
    };
  },
  created() {
    this.getData({});
  },
  methods: {
    async getData(obj) {
      this.liveList = [];
      this.cardList = [];
      obj.page = this.page;
      console.log("这是第几页", this.page);
      obj.pageSize = this.pageSize;
      obj.state = 0;
      obj.startMin = obj.startMin ? String(obj.startMin) : "";
      obj.startMax = obj.startMax ? String(obj.startMax) : "";
      this.queryForm = { ...obj };
      const res = await apiLive.list(obj);
      if (res.data && obj.state === 0) {
        if (
          res.data.list &&
          Array.isArray(res.data.list) &&
          res.data.list.length > 0
        ) {
          // this.cardList = res.data.list.map((element) => {
          //   console.log("结果", element);
          //   return { ...element, time: publicfun.timejudge(element.gmtStart) };
          // });
          res.data.list.forEach((element) => {
            let ava = "";
            const random = Math.floor(Math.random()) * 10 + 1;
            if (window.Config.isDemostrate) {
              ava = getAssetsFile(`teacher${random}.png`, "png");
            } else {
              ava = element.lecturerPhoto;
            }
            this.cardList.push({
              ...element,
              lecturerPhoto: ava,
            });
          });
        }
      }
      if (this.cardList.length < 15) {
        this.turnnum = this.cardList.length;
        this.loadAssests();
      }
    },
    //添加假数据
    async loadAssests() {
      let assetsList = [];
      const res = await commonApi.GetDocData("living");
      if (res && res.code === 200) {
        assetsList = res.data.living.map((item) => {
          const avasuffix = item.lecturerPhoto.split(".")[1];
          const imgSuffix = item.cover.split(".")[1];
          return {
            ...item,
            isFake: true,
            isBook: false,
            cover: getAssetsFile(item.cover, imgSuffix),
            lecturerPhoto: getAssetsFile(item.lecturerPhoto, avasuffix),
          };
        });
        const num = this.pageSize - this.cardList.length;
        this.cardList = this.cardList.concat(assetsList.splice(0, num));
      }
    },
    // 切换分页
    getCardList(val) {
      this.page = val.pageCurrent;
      this.pageSize = val.pageSize;
      this.getData(this.queryForm);
    },
    fakeRefresh(index, bor) {
      console.log(this.turnnum, "真数据长度");
      console.log(index, bor, "传参");
      this.cardList[index].isBook = !this.cardList[index].isBook;
      console.log(this.cardList[index].isBook);
    },
  },
});
</script>

<style lang="scss" scoped>
.main_content {
  flex-direction: column;
}
.el-main {
  padding: 20px 20px 0 0;
}
.layout {
  min-height: 500px;
  .Grid {
    display: grid;
    grid-template-columns: 280px 280px 280px 280px;
    grid-template-rows: 150px;
    grid-gap: 0px 20px;
  }
}
.select-con {
  margin-top: -15px;
}
</style>
